















































































































































































import { Respondent } from '@app/models';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';
import { ResearchModule } from '../../store/modules/research-module';

@Component({
  components: { ResearchTargetTabs },
})
export default class AdminRespondent extends Vue {
  public research = getModule(ResearchModule);

  public filter = null;

  public filterFormula = '';
  public filterTitle = '';
  public filterEmail = '';
  public researchId = '';
  public lockAdd = false;

  public currentPage = 1;
  public perPage = 15;

  public add: Respondent = {} as any;

  public targetGroup: string = 'mwb';
  public customFields: any = {
    mwb: [],
    kl: [],
  };

  public get fields() {
    return [
      // {
      //   key: 'formula',
      //   label: this.$t('account:formula.name'),
      //   sortable: true,
      //   thClass: 'th-formula',
      //   tdClass: 'td-formula'
      // },
      {
        key: 'research',
        label: this.$t('research-title'),
        sortable: true,
        thClass: 'th-research',
        tdClass: 'td-research',
      },
      {
        key: 'email',
        label: this.$t('email'),
        sortable: true,
        thClass: 'th-email',
        tdClass: 'td-email',
      },
      {
        key: 'first_name',
        label: this.$t('name-first'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
      },
      {
        key: 'name',
        label: this.$t('name-last'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
      },
      {
        key: 'target',
        label: this.$t('target-audience'),
        sortable: true,
        thClass: 'th-target',
        tdClass: 'td-target',
      },
      {
        key: 'status',
        label: this.$t('status'),
        sortable: true,
        thClass: 'th-status',
        tdClass: 'td-status',
      },
      ...(this.customFields[this.targetGroup] || []),
      {
        key: 'actions',
        label: '',
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ].filter(field => {
      // if (field.key === 'formula' || field.key === 'actions')
      //   return this.$user.admin;

      return true;
    });
  }

  public get filterPlaceholder() {
    return [
      this.$t('search-for'),
      this.$t('email'),
      this.$t('or'),
      this.$t('research-title'),
    ].join(' ');
  }

  public get pageTitle() {
    let tmp = !this.researchId ? ' <u>' + this.$t('all') + '</u> ' : ' ';
    return this.$t('manage') + tmp + this.$t('respondents');
  }

  public get currentTargetGroup() {
    return this.$FEATURES.target.filter(target => {
      return target.includes(this.targetGroup);
    });
  }

  public get currentResearch() {
    return this.research.find(this.researchId);
  }

  public get formIsComplete() {
    const complete = Object.keys(this.add).every(key => {
      return (this.add as any)[key] !== '';
    });

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmail = re.test(this.add.email);

    return complete && isEmail;
  }

  public get targets() {
    const research = this.research.find(this.researchId);
    if (!research) return this.$targetTabs;

    return research.target.reduce((acc: string[], t: any) => {
      const target = t.split('-')[0];
      if (acc.includes(target)) {
        return acc;
      }

      return [...acc, target];
    }, []);
  }

  public get respondents() {
    return this.research.respondents
      .filter(
        r =>
          r.company === this.$company.id ||
          (this.researchId && r.company === '<demo>')
      )
      .filter(r => r.target.startsWith(this.targetGroup))
      .map(r => this.toItem(r))
      .filter(i => {
        if (this.researchId) {
          const check = this.researchId;
          return i.rid.toLowerCase() === check.toLowerCase();
        }

        if (this.filterFormula) {
          const check = this.filterFormula.toLowerCase();
          return i.formula.toLowerCase().indexOf(check) >= 0;
        }

        if (this.filterTitle) {
          const check = this.filterTitle.toLowerCase();
          return i.research.toLowerCase().indexOf(check) >= 0;
        }

        if (this.filterEmail) {
          const check = this.filterEmail.toLowerCase();
          return i.email.toLowerCase().indexOf(check) >= 0;
        }

        return true;
      });
  }

  async created() {
    this.add = this.empty();
    await this.research.bind();

    this.onQuery();
  }

  @Watch('$route.query', { deep: true })
  private onQuery() {
    this.filterFormula = this.$route.query.formula as string;
    this.filterTitle = this.$route.query.title as string;
    this.filterEmail = this.$route.query.email as string;
    this.researchId = this.$route.query.research as string;

    const research = this.research.find(this.researchId);
    this.targetGroup = research ? research.target[0].split('-')[0] : '...';
    this.customFields = research ? research.customFields : {};
  }

  private toItem(respondent: Respondent): Item {
    const company = this.$company.find(respondent.company);
    const research = this.research.find(respondent.rid);

    let status: string = respondent.status;
    if (!this.$user.admin && (status === 'started' || status === 'done'))
      status = 'invited';

    const progress = Math.floor(respondent.progress);
    const percentage = (progress > 100 ? 100 : progress) + '%';

    return {
      ...respondent,
      id: respondent.id,
      rid: respondent.rid,
      formula: company ? company.name : '',
      research: research ? research.title : '',
      email: respondent.email,
      target: this.$t('target-audience-' + respondent.target),
      status: status === 'started' ? percentage : this.$t(status),
      canEdit: respondent.status !== 'started' && respondent.status !== 'done',
      canNotify:
        !!research && research.status !== 'complete' && status !== 'done',
    };
  }

  public get canAdd() {
    if (this.lockAdd) return false;
    const { email, first_name, name, gender, target } = this.add;
    return !!email && !!first_name && !!name && !!gender && !!target;
  }

  public async addRespondent() {
    if (this.respondents.find(r => r.email === this.add.email)) {
      this.$bce.message(this.$t('user-already-added'));
      this.add = this.empty();
      return;
    }

    this.lockAdd = true;
    await this.$firebase
      .doc(`research/${this.researchId}/respondent/${this.add.id}`)
      .set(this.add)
      .catch(() => {});
    this.lockAdd = false;

    const name = `${this.add.first_name} ${this.add.name}`;
    this.$bce.message(this.$t('research-is-sent-to', { item: name }));
    this.add = this.empty();
  }

  private empty(): Respondent {
    const newField = (this.customFields[this.targetGroup] || []).reduce(
      (acc: any, val: any) => {
        acc[val.key] = '';
        return acc;
      },
      {}
    );

    const respondent: Respondent = {
      id: this.$firebase.generateId(),
      rid: this.$route.query.research as string,
      company: this.$company.id!,
      name: '',
      gender: 'male',
      email: '',
      target: '' as any,
      progress: 0,
      status: 'idle',
    };

    return { ...respondent, ...newField };
  }

  public getCategory(name: string) {
    return this.$company.categories.find(c => c.name === name);
  }

  public sendReminder(item: Item) {
    this.$firebase.admin.topic('research-reminder', {
      research: item.rid,
      respondent: item.id,
    });
  }

  public async deleteRespondent(item: Item) {
    const title = this.$t('warning');
    const message = this.$t('confirm-delete-respondent', { email: item.email });
    const cancel = this.$t('cancel');
    const ok = this.$t('delete-item', { item: this.$t('respondent') });
    const confirm = await this.$bce.confirm(title, message, { cancel, ok });
    if (!confirm) return;

    return this.$firebase
      .doc(`research/${item.rid}/respondent/${item.id}`)
      .delete();
  }

  public async editRespondent(item: Item) {
    const t = this.$t;

    const email = await this.$bce.prompt(
      `${t('edit')} ${t('email').toLowerCase()}`,
      item.email,
      t('email'),
      { validation: 'required|email', cancel: t('cancel'), ok: t('true') }
    );

    if (!email) return;
    if (this.respondents.find(r => r.email === this.add.email)) {
      this.$bce.message(this.$t('user-already-added'));
      return;
    }

    const respondent = await this.$firebase.runTransaction(
      async transaction => {
        const path = (id: string) => `research/${item.rid}/respondent/${id}`;
        const nextId = this.$firebase.generateId();
        const prevRef = this.$firebase.doc(path(item.id));
        const nextRef = this.$firebase.doc(path(nextId));

        const data = await transaction
          .get(prevRef)
          .then(snap => this.$firebase.toData<Respondent>(snap));
        if (!data) return;

        const respondent: Respondent = {
          ...data,
          email,
          id: nextId,
          status: 'idle',
        };

        transaction.delete(prevRef);
        transaction.set(nextRef, respondent);
        return respondent;
      }
    );

    if (respondent) {
      const message = this.$t('research-is-sent-to', { item: respondent.name });
      this.$bce.message(message);
    }
  }
}

interface Item {
  readonly id: string;
  readonly rid: string;
  readonly formula: string;
  readonly research: string;
  readonly email: string;
  readonly status: string;
  readonly target: string;
  readonly canEdit: boolean;
  readonly canNotify: boolean;
}
